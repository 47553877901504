export default function Services() {

    return <section className="container py-10 services">
        <h1 className="fw-bold mb-3 mb-lg-5 text-center" id="nossos-servicos">Nossos serviços</h1>

        <div className="row gap-5">

            <div className="col-lg px-lg-0 px-5 text-lg-start text-center my-3 my-lg-0">
                <div className="d-flex justify-content-center">
                    <img src="/images/services/custom-systems.svg" className="img-item-service"/>
                </div>
                <h4 className="fw-bold mb-3">Sistemas personalizados</h4>
                <p>
                    Oferecemos soluções em sistemas personalizados para empresas de todos os tamanhos. Nossa equipe de especialistas cria softwares sob medida que atendem às necessidades específicas do seu negócio, garantindo eficiência e agilidade. Desde sistemas de gestão até plataformas de integração de dados, desenvolvemos soluções tecnológicas que otimizam seus processos internos, melhorando o desempenho da sua equipe.
                </p>
                <p>
                    Com o desenvolvimento de sistemas personalizados, você terá uma solução única e adaptada à sua empresa, potencializando seus resultados e facilitando a tomada de decisões. Transforme seu negócio com a tecnologia certa.
                </p>
            </div>

            <div className="col-lg px-lg-0 px-5 text-lg-start text-center my-3 my-lg-0">
                <div className="d-flex justify-content-center">
                    <img src="/images/services/mobile.svg" className="img-item-service"/>
                </div>
                <h4 className="fw-bold mb-3">Aplicativos</h4>
                <p>
                    Criamos aplicativos sob medida que atendem às demandas do seu negócio. Seja para Android, iOS, ou ambas as plataformas, desenvolvemos apps empresariais personalizados que melhoram a interação com seus clientes e otimizam a gestão interna. Nossos aplicativos são projetados para oferecer usabilidade e performance, proporcionando uma experiência incrível ao usuário final.
                </p>
                <p>
                    Com foco em soluções inovadoras e de alta performance, o nosso time garante o desenvolvimento de aplicativos personalizados que colocam sua empresa à frente da concorrência. Invista em tecnologia mobile e amplie o alcance da sua marca!
                </p>
            </div>

        </div>
    </section>
}
