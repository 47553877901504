export default function AboutUs() {
    return <section className="container-fluid">
        <div className="container py-10">
            <h1 className="text-center fw-bold mb-5" id="sobre-nos">Sobre nós</h1>
            <div className="row">
                <div className="col-lg-6">
                    <img src="/images/about-us/about.svg" alt="Sobre nós"/>
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <h3 className="fw-bolder mb-3">
                        Fornecer ao seu negócio um serviço de TI de qualidade é a nossa paixão
                    </h3>
                    <p>
                        Temos por <strong className="text-danger">Missão</strong> melhorar a vida das pessoas simplificando processos por meio de serviços humanizados e originais.
                    </p>
                    <p>
                        Nosso <strong className="text-danger">Valor</strong> é ser uma empresa reconhecida por sua excelência no desenvolvimento de softwares e inovação de produtos digitais.
                    </p>
                </div>
            </div>
        </div>

        <div className="container py-10">
            <h1 className="text-center fw-bold mb-5" id="valores">Valores</h1>
            <div className="row">
                <div className="col-lg-6 text-center order-0 order-lg-0">
                    <img src="/images/objectives/resultados-com-excelencia.svg" alt="Resultados com excelência" className="img-fluid w-50"/>
                </div>
                <div className="col-lg-6 p-3 p-lg-5 d-flex align-items-center order-1 order-lg-1">
                    <div>
                        <h3 className="fw-bolder mb-3">Resultados com excelência</h3>
                        <p>
                            Entregar serviços com qualidade é o que fidelizará nossos clientes e nos trará
                            novas indicações. Para isso, a Ironbug conta com profissionais qualificados que
                            têm condições de executar os serviços com máxima excelência.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-6 p-3 p-lg-5 d-flex align-items-center order-1 order-lg-0">
                    <div>
                        <h3 className="fw-bolder mb-3">Humanização e empatia</h3>
                        <p>
                            Ouvir as pessoas e o que elas precisam é essencial para a Ironbug. Isso se aplica a
                            todas as relações, entre empresa e parceiros, empresa e clientes e empresa e
                            funcionários. Ouvir e entender as necessidades das pessoas.
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 text-center order-0 order-lg-1">
                    <img src="/images/objectives/humanizacao-e-empatia.svg" alt="Humanização e empatia" className="img-fluid w-50"/>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-6 text-center order-0 order-lg-0">
                    <img src="/images/objectives/somos-verdadeiros.svg" alt="Somos verdadeiros" className="img-fluid w-50"/>
                </div>
                <div className="col-lg-6 p-3 p-lg-5 d-flex align-items-center order-1 order-lg-1">
                    <div>
                        <h3 className="fw-bolder mb-3">Somos verdadeiros</h3>
                        <p>
                            A honestidade é um valor que sempre esteve presente na Ironbug e é critério para
                            todos os processos internos e contratação de profissionais. Acreditamos no
                            trabalho limpo e transparente com nossos parceiros e clientes.
                        </p>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-6 p-3 p-lg-5 d-flex align-items-center order-1 order-lg-0">
                    <div>
                        <h3 className="fw-bolder mb-3">Inovação e tendências</h3>
                        <p>
                            Não ficar ultrapassada é um valor fundamental da Ironbug. A empresa motiva e
                            possibilita que sua equipe esteja sempre em contato com as novidades da
                            tecnologia e as tendências de mercado para ter condições de trazer soluções
                            inovadoras para os clientes.
                        </p>
                    </div>
                </div>
                <div className="col-lg-6 text-center order-0 order-lg-1">
                    <img src="/images/objectives/inovacao-e-tendencia.svg" alt="Inovação e tendências" className="img-fluid w-50"/>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-6 text-center">
                    <img src="/images/objectives/compartilhar-conhecimento.svg" alt="Compartilhar conhecimento" className="img-fluid w-50"/>
                </div>
                <div className="col-lg-6 p-3 p-lg-5 d-flex align-items-center">
                    <div>
                        <h3 className="fw-bolder mb-3">Compartilhar conhecimento</h3>
                        <p>
                            Um mundo melhor é composto por pessoas melhores. Acreditamos que o
                            conhecimento tem o potencial de melhorar as pessoas.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </section>
}
