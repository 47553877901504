import items from "./items.json";
import Carousel from "react-multi-carousel";

export default function Partners() {
    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 4
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 3
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    return <section className="container py-10 partners-section">
        <h1 className="text-center fw-bold mb-5" id="cases">Parceiros</h1>
        <Carousel
            responsive={responsive}
            showDots={true}
            swipeable={true}
            draggable={true}
            autoPlay={true}
            arrows={true}
            infinite={true}>

            {items.map((item, index) => {
                return <img src={item.logo} alt={item.name} key={index}/>
            })}
        </Carousel>
    </section>;
}
