import GoTop from "../Main/GoTop";
import {FaInstagram, FaLinkedin} from "react-icons/fa";

export default function Footer() {
    return <footer>
        <section className="footer-area text-center text-lg-start">
            <div className="container">
                <div className="d-lg-flex justify-content-between">
                    <div className="col-lg-4">
                        <img src="logo.png" className="mb-4" height={60}/>
                        <p>
                            Transformando sistemas em soluções
                        </p>
                    </div>
                    <div className="col-lg-3">
                    </div>
                    <div className="col-lg-4">
                        <h4 className="mb-3">Contato</h4>
                        <ul className="footer-nav-links">
                            <li><strong>Endereço: </strong> Av. Carneiro Leão, 294 - Sala 401, Maringá/PR</li>
                            <li><strong>Telefone: </strong> 44 9109-7406</li>
                            <li><strong>E-mail: </strong> contato@ironbug.com.br</li>
                        </ul>

                        <div className="d-flex justify-content-center justify-content-lg-start gap-2">
                            <a href="https://www.instagram.com/ironbug.sistemas/" className="link-danger">
                                <FaInstagram size={30}></FaInstagram>
                            </a>

                            <a href="https://www.linkedin.com/company/ironbug/" className="link-danger">
                                <FaLinkedin size={30}></FaLinkedin>
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section className="copyright-area">
            <div className="container">
                Ironbug Soluções Tecnológicas Ltda © {new Date().getFullYear()}. CNPJ: 27.499.855/0001-65
            </div>
        </section>
        <GoTop/>
    </footer>
}


