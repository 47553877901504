import React from "react";
import {Link} from "react-router-dom";

export default function Header() {
    return (
        <div id="menu">
            <div className="container">
                <header className="d-flex flex-wrap justify-content-center py-3 mb-4">
                    <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                        <img src="logo.png" height={60}/>
                    </a>

                    <nav className="nav nav-item">
                        <li className="nav-item"><a href="/#nossos-servicos" className="nav-link">Nossos serviços</a></li>
                        <li className="nav-item">
                            <Link to={`AboutUs`} className="nav-link">Sobre nós</Link>
                        </li>
                        <li className="nav-item">
                            <Link to={`Cases`} className="nav-link">Cases</Link>
                        </li>
                    </nav>
                </header>
            </div>
        </div>
    );
}
