import items from "./items.json";
import Carousel from "react-multi-carousel";

import 'react-multi-carousel/lib/styles.css';

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 1
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1
    }
};
export default function Cases() {
    return <section className="container">
        <h1 className="text-center fw-bold mb-5" id="cases">Cases</h1>
        {items.map((item, index) => {
            const carrousel = <div className="col-lg-6 mb-5">
                <Carousel key={index}
                          responsive={responsive}
                          showDots={true}
                          swipeable={true}
                          arrows={true}>

                    {item.images.map(image =>
                        <img src={image} className="img-fluid" alt={item.title}/>
                    )}
                </Carousel>
            </div>;

            const description = <div className="col-lg-6 d-flex align-items-center">
                <div>
                    <h1 className="fw-bold mt-3">{item.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: item.description}}></p>
                </div>
            </div>;

            return <div className="row mb-2 mb-md-5 d-flex justify-content-center">
                {carrousel}
                {/*{description}*/}
            </div>;
        })}
    </section>;
}

